export default {
    DUMMYDataPOST: {
        './api/v1/route/editor/add/3/19_': {
            data: {
                data: {
                    "parent_rob_id": null,
                    "rob_sub_id": null,
                    "out_field_name": "",
                    "out_name": "",
                    "out_rob_id": 1,
                    "out_rob_description": "",
                    "out_rob_type": "RLC"
                },
                status: 200
            }
        },
        './api/v1/auth/login1': {
            data: {
                data: {
                    "success": true,
                    "access_token": "AAABBBCCC",
                },
                error: {
                    code: 0,
                    trace: [],
                    message: "success"
                }
            },
            status: {}
        },
        './api/v1/auth/logout1': {
            data: {
                data: {},
                error: {
                    code: 200,
                    traceroute: '',
                    message: ""
                }
            },
            status: {}
        },
        './api/v1/partner1': {
            data: {
                data: {
                    id: "123",
                    "success": true,
                }
            },
            status: 200
        },
    },
    DUMMYDataPUT: {
        './api/v1/agentgroup/1_': {
            data: {
                id: "1",
                "success": true,
            },
            status: 200
        },
        // './api/v1/agentgroup/1': {
        //     data: {
        //         id: "1",
        //         "success": true,
        //     },
        //     status: 200
        // },
        './api/v1/partner/1_': {
            data: {
                data: {
                    id: "123",
                    "success": true,
                }
            },
            status: 200
        },
    },
    DUMMYDataDELETE: {
        './api/v1/partner/1/agent/1/address/3_': {
            data: {
                data: {
                    success: true,
                },
                status: 200
            }
        },
        './api/v1/partner/1/agent/1/address/4_': {
            data: {
                data: {
                    success: true,
                },
                status: 200
            }
        },
        './api/v1/partner/1': {
            data: {
                data: {
                    id: "123",
                    "success": true,
                }
            },
            status: 200
        },
    },
    DUMMYDataGET: {
        "./api/v1/info/last-build-version": {
            data: {
                "data": {
                    version: "1.0.0",
                    build_time: "2021.11.18 10:15"
                },
                "error": { "code": 0, "message": "Success.", "trace": [] }
            },
            status: 200
        },
        "./api/v1/info/version-history_": {
            data: {
                "data": [],
                "error": { "code": 0, "message": "Success.", "trace": [] }
            },
            status: 200
        },
        './api/v1/auth/my-details1': {
            data: {
                data: {
                    "id": 1,
                    "partner_id": 1,
                    "first_name": "Márk",
                    "last_name": "Balugyánszki",
                    "name": "Balugyánszki Márk",
                    "firm_name": "Nem tudom",
                    "email": "b.mark@on-demand.hu",
                    "is_technical": false,
                    "tax_number": "12345678-1-12",
                    "company_reg_number": "123456ASDCASD78",
                    "mnb_reg_number": "123456ASDCASD78",
                    "birth_date": "1996-08-09",
                    "birth_place": "Hatvan",
                    "mother_name": null,
                    "phone": "+36301234567",
                    "comment": "nincs",
                    "gender": "male",
                    "addresses": [
                        {
                            "id": 3,
                            "address_type": "partner",
                            "zip_code": "1234",
                            "city": "Budapest",
                            "address": "Teszt utca 1",
                            "contact_phone": "+36201234567",
                            "contact_email": "test@test.com"
                        },
                        {
                            "id": 4,
                            "address_type": "partner",
                            "zip_code": "567-",
                            "city": "Gyömrő",
                            "address": "Teszt utca 2",
                            "contact_phone": "+36209876543",
                            "contact_email": "test1@test.com"
                        }
                    ],
                    "deleted_at": null,
                    "authenticated": true
                }
            },
            status: 200
        },
        './api/v1/auth/isauthenticated1': {
            data: {
                data: {
                    "authenticated": true,
                    "token": "AAABBBCCC"
                },
                "error": {
                    "code": 0,
                    "message": "Success.",
                    "trace": []
                },
            },
        },
        './api/v1/user/rights': {
            data: {
                data: {
                    "rights": [
                        "ZOLD-NK-KIMENO_EDIT",
                        "ZOLD-NK-KIMENO_VIEW",
                        "ZOLD-NK-KIMENO_IMPORT",
                        "ROVID_EDIT",
                        "ROVID_VIEW",
                        "GTS-UPLOAD",
                        "ZOLD-NK-KIMENO_FINALIZE",
                        "TAVSZAVAZAS_FINALIZE",
                        "ROVID_FINALIZE",
                        "CONFIGURATION-OTHER_EDIT",
                        "CONFIGURATION-OTHER_VIEW",
                    ]
                }
            },
            status: 200
        },
        './api/v1/agent/1_': {
            data: {
                data: {
                    "id": "id_1",
                    "createdAt": "2021-09-24 18:45",
                    "subject": "Lorem ipsum dolor",
                    "body": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce venenatis urna eget nisi vehicula imperdiet. Ut nec nisi feugiat, lobortis lectus non, feugiat metus. Vivamus laoreet turpis vel dui varius, vitae luctus purus scelerisque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean mollis, urna et pellentesque malesuada, orci est fringilla ipsum, vitae tristique nisl sem et dui. Vestibulum sapien ante, rutrum vel dolor eget, finibus accumsan risus. Quisque porta nunc lectus.",
                    "agentGroupId": "id_01",
                    "agentGroupName": "Agent group A",
                    "agents": [],
                    "status": 50,
                    "agentsHelper": [
                        {
                            "id": "id_1",
                            "firmName": "username",
                            "lastName": "Agent",
                            "firstName": "Name",
                            "read": null
                        },
                        {
                            "id": "id_2",
                            "firmName": "username",
                            "lastName": "Agent",
                            "firstName": "Name",
                            "read": "2021-09-24 20:00"
                        }
                    ]
                },
            },
            status: 200
        },
        './api/v1/messages/2/message_agent_informations_list': {
            data: {
                data: [
                    {
                        "id": 1,
                        "agent_id": 1,
                        "agent_first_name": "Krisztián",
                        "agent_last_name": "Vincze",
                        "created_at": "2021-09-24 18:45",
                        "sent_at": "2021-09-24 18:55",
                        "read_at": "2021-09-25 08:35",
                        "deleted_at": "2021-09-26 18:45",
                    },
                    {
                        "id": 2,
                        "agent_id": 2,
                        "agent_first_name": "Viktória",
                        "agent_last_name": "Boros",
                        "created_at": "2021-09-24 18:45",
                        "sent_at": "2021-09-24 18:45",
                        "read_at": "",
                        "deleted_at": "",
                    },
                    {
                        "id": 3,
                        "agent_id": 3,
                        "agent_first_name": "Agent",
                        "agent_last_name": "User",
                        "created_at": "2021-09-24 18:45",
                        "sent_at": "2021-09-24 18:45",
                        "read_at": "2021-09-24 18:45",
                        "deleted_at": "2021-09-24 18:45",
                    },
                    {
                        "id": 4,
                        "agent_id": 3,
                        "agent_first_name": "Agent",
                        "agent_last_name": "User",
                        "created_at": "2021-09-24 18:45",
                        "sent_at": "2021-09-24 18:45",
                        "read_at": "2021-09-24 18:45",
                        "deleted_at": "2021-09-24 18:45",
                    },
                    {
                        "id": 5,
                        "agent_id": 3,
                        "agent_first_name": "Agent",
                        "agent_last_name": "User",
                        "created_at": "2021-09-24 18:45",
                        "sent_at": "2021-09-24 18:45",
                        "read_at": "2021-09-24 18:45",
                        "deleted_at": "2021-09-24 18:45",
                    },
                    {
                        "id": 6,
                        "agent_id": 3,
                        "agent_first_name": "Agent",
                        "agent_last_name": "User",
                        "created_at": "2021-09-24 18:45",
                        "sent_at": "2021-09-24 18:45",
                        "read_at": "2021-09-24 18:45",
                        "deleted_at": "2021-09-24 18:45",
                    },
                    {
                        "id": 7,
                        "agent_id": 3,
                        "agent_first_name": "Agent",
                        "agent_last_name": "User",
                        "created_at": "2021-09-24 18:45",
                        "sent_at": "2021-09-24 18:45",
                        "read_at": "2021-09-24 18:45",
                        "deleted_at": "2021-09-24 18:45",
                    },
                    {
                        "id": 8,
                        "agent_id": 3,
                        "agent_first_name": "Agent",
                        "agent_last_name": "User",
                        "created_at": "2021-09-24 18:45",
                        "sent_at": "2021-09-24 18:45",
                        "read_at": "2021-09-24 18:45",
                        "deleted_at": "2021-09-24 18:45",
                    },
                    {
                        "id": 9,
                        "agent_id": 3,
                        "agent_first_name": "Agent",
                        "agent_last_name": "User",
                        "created_at": "2021-09-24 18:45",
                        "sent_at": "2021-09-24 18:45",
                        "read_at": "2021-09-24 18:45",
                        "deleted_at": "2021-09-24 18:45",
                    },
                    {
                        "id": 10,
                        "agent_id": 3,
                        "agent_first_name": "Agent",
                        "agent_last_name": "User",
                        "created_at": "2021-09-24 18:45",
                        "sent_at": "2021-09-24 18:45",
                        "read_at": "2021-09-24 18:45",
                        "deleted_at": "2021-09-24 18:45",
                    },
                    {
                        "id": 11,
                        "agent_id": 3,
                        "agent_first_name": "Agent",
                        "agent_last_name": "User",
                        "created_at": "2021-09-24 18:45",
                        "sent_at": "2021-09-24 18:45",
                        "read_at": "2021-09-24 18:45",
                        "deleted_at": "2021-09-24 18:45",
                    },
                    {
                        "id": 12,
                        "agent_id": 3,
                        "agent_first_name": "Agent",
                        "agent_last_name": "User",
                        "created_at": "2021-09-24 18:45",
                        "sent_at": "2021-09-24 18:45",
                        "read_at": "2021-09-24 18:45",
                        "deleted_at": "2021-09-24 18:45",
                    },
                    {
                        "id": 13,
                        "agent_id": 3,
                        "agent_first_name": "Agent",
                        "agent_last_name": "User",
                        "created_at": "2021-09-24 18:45",
                        "sent_at": "2021-09-24 18:45",
                        "read_at": "2021-09-24 18:45",
                        "deleted_at": "2021-09-24 18:45",
                    },
                    {
                        "id": 14,
                        "agent_id": 3,
                        "agent_first_name": "Agent",
                        "agent_last_name": "User",
                        "created_at": "2021-09-24 18:45",
                        "sent_at": "2021-09-24 18:45",
                        "read_at": "2021-09-24 18:45",
                        "deleted_at": "2021-09-24 18:45",
                    },
                    {
                        "id": 15,
                        "agent_id": 3,
                        "agent_first_name": "Agent",
                        "agent_last_name": "User",
                        "created_at": "2021-09-24 18:45",
                        "sent_at": "2021-09-24 18:45",
                        "read_at": "2021-09-24 18:45",
                        "deleted_at": "2021-09-24 18:45",
                    },
                    {
                        "id": 16,
                        "agent_id": 3,
                        "agent_first_name": "Agent",
                        "agent_last_name": "User",
                        "created_at": "2021-09-24 18:45",
                        "sent_at": "2021-09-24 18:45",
                        "read_at": "2021-09-24 18:45",
                        "deleted_at": "2021-09-24 18:45",
                    },
                    {
                        "id": 17,
                        "agent_id": 3,
                        "agent_first_name": "Agent",
                        "agent_last_name": "User",
                        "created_at": "2021-09-24 18:45",
                        "sent_at": "2021-09-24 18:45",
                        "read_at": "2021-09-24 18:45",
                        "deleted_at": "2021-09-24 18:45",
                    },
                    {
                        "id": 18,
                        "agent_id": 3,
                        "agent_first_name": "Agent",
                        "agent_last_name": "User",
                        "created_at": "2021-09-24 18:45",
                        "sent_at": "2021-09-24 18:45",
                        "read_at": "2021-09-24 18:45",
                        "deleted_at": "2021-09-24 18:45",
                    },
                    {
                        "id": 19,
                        "agent_id": 3,
                        "agent_first_name": "Agent",
                        "agent_last_name": "User",
                        "created_at": "2021-09-24 18:45",
                        "sent_at": "2021-09-24 18:45",
                        "read_at": "2021-09-24 18:45",
                        "deleted_at": "2021-09-24 18:45",
                    },
                    
                ]
            },
            status: 200
        },
        './api/v1/messages/2_': {
            data: {
                data:
                {
                    "id": 2,
                    "created_at": "2021-09-24 18:45",
                    "start_sending_at": "2021-10-01 10:00",
                    "subject": "Lorem ipsum dolor 1",
                    "body": "Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor ",
                    "participants": {
                        "agents": [
                            { "id": 1 },
                            { "id": 4 },
                            { "id": 5 }
                        ],
                        "agent_groups": [
                            { "id": 1 },
                            { "id": 4 },
                            { "id": 5 }
                        ]
                    },
                    "status": "EDIT",
                }
            },
            status: 200
        },
        './api/v1/messages_': {
            data: {
                data: [
                    {
                        "id": 1,
                        "created_at": "2021-09-24 18:45",
                        "start_sending_at": "2021-10-01 10:00",
                        "subject": "Lorem ipsum dolor 1",
                        "status": "EDIT",
                    },
                    {
                        "id": 2,
                        "created_at": "2021-09-24 18:45",
                        "start_sending_at": "2021-10-01 10:00",
                        "subject": "Lorem ipsum dolor 2",
                        "status": "SENDING",
                    },
                    {
                        "id": 3,
                        "created_at": "2021-09-24 18:45",
                        "start_sending_at": "2021-10-01 10:00",
                        "subject": "Lorem ipsum dolor 3",
                        "status": "POSTED",
                    }
                ]
            },
            status: 200
        },
        './api/v1/bank_': {
            data: {
                data: [
                    {
                        "id": 1,
                        "createdAt": "2021-09-24 18:45",
                        "name": "Budapest Bank",
                        "dislay_name": "Budapest Bank",
                        "active": "Y"
                    },
                    {
                        "id": 2,
                        "createdAt": "2021-09-24 18:45",
                        "name": "Cetelem Bank",
                        "dislay_name": "Cetelem",
                        "active": "Y"
                    },
                    {
                        "id": 3,
                        "createdAt": "2021-09-24 18:45",
                        "name": "Cib Bank",
                        "dislay_name": "CIB Bank",
                        "active": "Y"
                    },
                    {
                        "id": 4,
                        "createdAt": "2021-09-24 18:45",
                        "name": "Erste Bank",
                        "dislay_name": "Erste Bank",
                        "active": "Y"
                    },
                ]
            },
            status: 200
        },
        './api/v1/partner1': {
            data: {
                data: [
                    {
                        "id": 1,
                        "created_at": "2021-09-24 18:45",
                        "name": "Partner AA",
                        "firm_name": "Partner AA Kft.",
                        "active": "Y",
                        "contact_name": "Contact Name A",
                        "contact_phone": "+00 000 0000",
                        "contact_email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 11,
                                "addressType": "Headquarters",
                                "zip_code": "0000",
                                "city": " New York City",
                                "address": "Sesame street 42.",
                                "contact_phone": "+00 000 0000",
                                "contact_email": "example@mail.hu"
                            },
                            {
                                "id": 12,
                                "addressType": "Billing address",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contact_phone": "+00 000 0000",
                                "contact_email": "example@mail.hu"
                            }
                        ]
                    },
                    {
                        "id": 2,
                        "created_at": "2021-09-24 18:45",
                        "name": "Partner BB",
                        "firm_name": "Partner BB Kft.",
                        "active": "N",
                        "contact_name": "Contact Name B",
                        "contact_phone": "+00 000 0000",
                        "contact_email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 21,
                                "addressType": "Headquarters",
                                "zip_code": "0000",
                                "city": " New York City",
                                "address": "Sesame street 42.",
                                "contact_phone": "+00 000 0000",
                                "contact_email": "example@mail.hu"
                            }
                        ]
                    }
                ]
            },
            status: 200
        },
        './api/v1/agent/1_': {
            data: {
                data: {
                    "id": "1",
                    "createdAt": "2021-09-24 18:45",
                    "technical": "N",
                    "firm_name": "Vincze Krisztián EV",
                    "password": "password",
                    "last_name": "Vincze",
                    "first_name": "Krisztián",
                    "gender": "male",
                    "birth_date": "1990-09-01",
                    "birth_place": "Budapest",
                    "mother_name": "Mother Name",
                    "active": "Y",
                    "taxnumber": "0123456-2-43",
                    "company_reg_number": "0123456789",
                    "mnb_reg_number": "98765432",
                    "phone": "+00 000 0000",
                    "email": "example@mail.hu",
                    "comment": "Lorem ipsum dolor sit amet.",
                    "addresses": [
                        {
                            "id": 31,
                            "address_type": "BASE",
                            "zip_code": "0000",
                            "city": "Budapest",
                            "address": "Fő út 12.",
                            "contactPhone": "+00 000 0000",
                            "contactEmail": "example@mail.hu"
                        }
                    ],
                    "roles": [
                        "id_1",
                        "id_3",
                        "id_4"
                    ],
                    "partner_id": 1,
                    "partner_name": "Partner A"
                }
            },
            status: 200
        },
        './api/v1/agent/2_': {
            data: {
                data: {
                    "id": "2",
                    "createdAt": "2021-09-24 18:45",
                    "technical": "N",
                    "firm_name": "Boros Viktória EV",
                    "password": "password",
                    "last_name": "Boros",
                    "first_name": "Viktória",
                    "gender": "female",
                    "birth_date": "1990-09-01",
                    "birth_place": "Budapest",
                    "mother_name": "Mother Name",
                    "active": "N",
                    "taxnumber": "0123456-2-43",
                    "company_reg_number": "0123456789",
                    "mnb_reg_number": "98765432",
                    "phone": "+00 000 0000",
                    "email": "example@mail.hu",
                    "comment": "Lorem ipsum dolor sit amet.",
                    "addresses": [
                        {
                            "id": 41,
                            "address_type": "BASE",
                            "zip_code": "0000",
                            "city": "Budapest",
                            "address": "Fő út 12.",
                            "contactPhone": "+00 000 0000",
                            "contactEmail": "example@mail.hu"
                        }
                    ],
                    "roles": [
                        "id_1",
                        "id_3",
                        "id_4"
                    ],
                    "partner_id": 1,
                    "partner_name": "Partner A"
                }
            },
            status: 200
        },
        './api/v1/agent/1/product_': {
            data: {
                data: [
                    { "id": "1", "name": "BB Bankszámla", "bank_id": 1, "bankDisplayName": "Budapest Bank", "expirationDate": "2021-10-16" },
                    { "id": "2", "name": "BB Bankszámla", "bank_id": 1, "bankDisplayName": "Budapest Bank", "expirationDate": "2021-10-16" },
                    { "id": "44", "name": "BB Bankszámla", "bank_id": 1, "bankDisplayName": "Budapest Bank", "expirationDate": "2021-10-16" },
                    { "id": "45", "name": "BB jelzálog", "bank_id": 1, "bankDisplayName": "Budapest Bank", "expirationDate": "2021-10-20" },
                    { "id": "46", "name": "BB Személyi kölcsön", "bank_id": 1, "bankDisplayName": "Budapest Bank", "expirationDate": "2023-03-22" },
                    { "id": "47", "name": "BB Mikro számla", "bank_id": 1, "bankDisplayName": "Budapest Bank", "expirationDate": "2023-09-24" },
                    { "id": "48", "name": "BB mikro hitel", "bank_id": 1, "bankDisplayName": "Budapest Bank", "expirationDate": "2023-09-24" },
                    { "id": "50", "name": "Cetelem vizsga", "bank_id": 2, "bankDisplayName": "Cetelem", "expirationDate": "2023-09-24" },
                    { "id": "36", "name": "CIB Kombinált vizsga", "bank_id": 3, "bankDisplayName": "CIB Bank", "expirationDate": "2023-09-24" },
                    { "id": "38", "name": "ERSTE Jelzálog", "bank_id": 4, "bankDisplayName": "Erste Bank", "expirationDate": "" },
                    { "id": "39", "name": "ERSTE Személyi kölcsön", "bank_id": 4, "bankDisplayName": "Erste Bank", "expirationDate": "2023-09-24" },
                    { "id": "40", "name": "ERSTE KKV", "bank_id": 4, "bankDisplayName": "Erste Bank", "expirationDate": "" },
                ]
            },
            status: 200
        },
        './api/v1/bank/1/product_': {
            data: {
                data: [
                    { "id": "44", "name": "BB Bankszámla", "bank_id": 1, "bankDisplayName": "Budapest Bank" },
                    { "id": "45", "name": "BB jelzálog", "bank_id": 1, "bankDisplayName": "Budapest Bank" },
                    { "id": "46", "name": "BB Személyi kölcsön", "bank_id": 1, "bankDisplayName": "Budapest Bank" },
                    { "id": "47", "name": "BB Mikro számla", "bank_id": 1, "bankDisplayName": "Budapest Bank" },
                    { "id": "48", "name": "BB mikro hitel", "bank_id": 1, "bankDisplayName": "Budapest Bank" },
                ]
            },
            status: 200
        },
        './api/v1/bank/2/product_': {
            data: {
                data: [
                    { "id": "50", "name": "Cetelem vizsga", "bank_id": 2, "bankDisplayName": "Cetelem" },
                ]
            },
            status: 200
        },
        './api/v1/bank/3/product_': {
            data: {
                data: [
                    { "id": "36", "name": "CIB Kombinált vizsga", "bank_id": 3, "bankDisplayName": "CIB Bank" },
                ]
            },
            status: 200
        },
        './api/v1/bank/4/product_': {
            data: {
                data: [
                    { "id": "38", "name": "ERSTE Jelzálog", "bank_id": 4, "bankDisplayName": "Erste Bank" },
                    { "id": "39", "name": "ERSTE Személyi kölcsön", "bank_id": 4, "bankDisplayName": "Erste Bank" },
                    { "id": "40", "name": "ERSTE KKV", "bank_id": 4, "bankDisplayName": "Erste Bank" },
                ]
            },
            status: 200
        },
        './api/v1/agent/2/product_': {
            data: {
                data: [
                    { "id": "44", "name": "BB Bankszámla", "bankDisplayName": "Budapest Bank", "expirationDate": "2023-09-24" },
                    { "id": "45", "name": "BB jelzálog", "bankDisplayName": "Budapest Bank", "expirationDate": "2023-10-24" },
                    { "id": "39", "name": "ERSTE Személyi kölcsön", "bankDisplayName": "Erste Bank", "expirationDate": "2023-09-24" },
                ]
            },
            status: 200
        },
        './api/v1/system': {
            data: {
                data: [
                    { "id": "1", "name": "GV CRM" },
                    { "id": "2", "name": "GV MobilApp" },
                ]
            },
            status: 200
        },
        './api/v1/system/1/role': {
            data: {
                data: [
                    { "id": "1", "name": "Adminisztrátor", "system_id": 1 },
                    { "id": "2", "name": "Operator", "system_id": 1 },
                ]
            },
            status: 200
        },
        './api/v1/system/2/role': {
            data: {
                data: [
                    { "id": "3", "name": "Adminisztrátor", "system_id": 2 },
                    { "id": "4", "name": "Hitelügyintéző", "system_id": 2 },
                    { "id": "5", "name": "Tippadó", "system_id": 2 },
                ]
            },
            status: 200
        },
        './api/v1/system/roles_': {
            data: {
                data: [
                    {
                        "id": 1,
                        "name": "CRM",
                        "active": "Y",
                        "roles": [
                            {
                                "id": 1,
                                "name": "Admnisztrátor",
                                "system_id": 1, "created_at": "2021-11-03 13:42:19", "updated_at": "2021-11-03 13:42:19"
                            },
                            {
                                "id": 2,
                                "name": "Operátor",
                                "system_id": 1, "created_at": "2021-11-03 13:42:19", "updated_at": "2021-11-03 13:42:19"
                            },
                            {
                                "id": 3,
                                "name": "Tartalomszerkesztő",
                                "system_id": 1, "created_at": "2021-11-03 13:42:19", "updated_at": "2021-11-03 13:42:19"
                            }
                        ],
                        "created_at": "2021-11-03 13:21:35",
                        "updated_at": "2021-11-03 13:21:35",
                        "deleted_at": null
                    },
                    {
                        "id": 2,
                        "name": "Mobil applikáció",
                        "active": "Y",
                        "roles": [
                            {
                                "id": 4,
                                "name": "Hitelügyintéző",
                                "system_id": 2, "created_at": "2021-11-03 13:42:19", "updated_at": "2021-11-03 13:42:19"
                            },
                            {
                                "id": 5,
                                "name": "Tippadó",
                                "system_id": 2, "created_at": "2021-11-03 13:42:19", "updated_at": "2021-11-03 13:42:19"
                            }
                        ],
                        "created_at": "2021-11-03 13:21:35",
                        "updated_at": "2021-11-03 13:21:35",
                        "deleted_at": null
                    },
                    {
                        "id": 3,
                        "name": "Központi tároló",
                        "active": "Y",
                        "roles": [
                            {
                                "id": 6,
                                "name": "Admnisztrátor",
                                "system_id": 3, "created_at": "2021-11-03 13:42:19", "updated_at": "2021-11-03 13:42:19"
                            },
                            {
                                "id": 7,
                                "name": "Operátor",
                                "system_id": 3, "created_at": "2021-11-03 13:42:19", "updated_at": "2021-11-03 13:42:19"
                            }
                        ],
                        "created_at": "2021-11-03 13:21:35",
                        "updated_at": "2021-11-03 13:21:35",
                        "deleted_at": null
                    }
                ]

            },
            status: 200
        },
        './api/v1/product_': {
            data: {
                data: [
                    { "id": "44", "name": "BB Bankszámla", "bank_id": 1, "bankDisplayName": "Budapest Bank", "active": "Y" },
                    { "id": "45", "name": "BB jelzálog", "bank_id": 1, "bankDisplayName": "Budapest Bank", "active": "Y" },
                    { "id": "46", "name": "BB Személyi kölcsön", "bank_id": 1, "bankDisplayName": "Budapest Bank", "active": "Y" },
                    { "id": "47", "name": "BB Mikro számla", "bank_id": 1, "bankDisplayName": "Budapest Bank", "active": "Y" },
                    { "id": "48", "name": "BB mikro hitel", "bank_id": 1, "bankDisplayName": "Budapest Bank", "active": "Y" },
                    { "id": "50", "name": "Cetelem vizsga", "bank_id": 2, "bankDisplayName": "Cetelem", "active": "Y" },
                    { "id": "36", "name": "CIB Kombinált vizsga", "bank_id": 3, "bankDisplayName": "CIB Bank", "active": "Y" },
                    { "id": "38", "name": "ERSTE Jelzálog", "bank_id": 4, "bankDisplayName": "Erste Bank", "active": "Y" },
                    { "id": "39", "name": "ERSTE Személyi kölcsön", "bank_id": 4, "bankDisplayName": "Erste Bank", "active": "Y" },
                    { "id": "40", "name": "ERSTE KKV", "bank_id": 4, "bankDisplayName": "Erste Bank", "active": "Y" },
                ]
            },
            status: 200
        },
        './api/v1/agentgroup_': {
            data: {
                data: [
                    {
                        "id": "1",
                        "name": "Agent group A",
                        "active": "Y",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "agents": [
                            {
                                "id": "1",
                            },
                            {
                                "id": "2",
                            }
                        ]
                    },
                    {
                        "id": "2",
                        "name": "Agent group B",
                        "active": "Y",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "agents": [
                            "3", "4"
                        ]
                    }
                ]
            },
            status: 200
        },
        './api/v1/partner/1/agent/1_': {
            data: {
                data: {
                    "data": {
                        "id": 1,
                        "partner_id": 1,
                        "first_name": "Márk",
                        "last_name": "Balugyánszki",
                        "name": "Balugyánszki Márk",
                        "firm_name": "Nem tudom",
                        "email": "b.mark@on-demand.hu",
                        "is_technical": "N",
                        "active": "Y",
                        "tax_number": "12345678-1-12",
                        "company_reg_number": "123456ASDCASD78",
                        "mnb_reg_number": "123456ASDCASD78",
                        "birth_date": "1996-08-08",
                        "birth_place": "Hatvan",
                        "mother_name": "Huffnager Pistiné",
                        "phone": "+36301234567",
                        "comment": "nincs",
                        "gender": "male",
                        "addresses": [
                            {
                                "id": 3,
                                "address_type": "BASE",
                                "zip_code": "2040",
                                "city": "Budaörs",
                                "address": "levendula u. 8.",
                                "contact_phone": "99887766",
                                "contact_email": "koxi@koxi.hu"
                            },
                            {
                                "id": 4,
                                "address_type": "SITE",
                                "zip_code": "213123",
                                "city": "123123123123",
                                "address": "asdasd",
                                "contact_phone": "1231",
                                "contact_email": "231@23.hu"
                            }
                        ],
                        "roles": [1, 2, 3, 6, 8],
                        "deleted_at": null
                    },
                    "error": {
                        "code": 0,
                        "message": "success",
                        "trace": [],
                        "data": null
                    }
                }
            },
            status: 200
        },
        './api/v1/partner/1/agent_': {
            data: {
                "data": [
                    {
                        "id": "1",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "Vincze Krisztián EV",
                        "password": "password",
                        "last_name": "Vincze",
                        "first_name": "Krisztián",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 51,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_1",
                            "id_3",
                            "id_4"
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                    {
                        "id": "2",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "Boros Viktória EV",
                        "password": "password",
                        "last_name": "Boros",
                        "first_name": "Viktória",
                        "gender": "female",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 61,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_1",
                            "id_3",
                            "id_4"
                        ],
                        "products": [
                            {
                                "id": "id_2",
                                "name": "Product B",
                                "bankDisplayName": "Bank A",
                                "expirationDate": "2021-09-24"
                            }
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                    {
                        "id": "3",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "username C",
                        "password": "password",
                        "last_name": "Agent",
                        "first_name": "Name",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 71,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_1",
                            "id_3",
                            "id_4"
                        ],
                        "products": [
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                    {
                        "id": "4",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "username D",
                        "password": "password",
                        "last_name": "Agent",
                        "first_name": "Name",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 81,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_3",
                            "id_4"
                        ],
                        "products": [
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                ]
            },
            status: 200
        },
        './api/v1/agent_': {
            data: {
                "data": [
                    {
                        "id": "1",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "Vincze Krisztián EV",
                        "password": "password",
                        "last_name": "Vincze",
                        "first_name": "Krisztián",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 91,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_1",
                            "id_3",
                            "id_4"
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                    {
                        "id": "2",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "username B",
                        "password": "password",
                        "last_name": "Agent",
                        "first_name": "Name",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 101,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_1",
                            "id_3",
                            "id_4"
                        ],
                        "products": [
                            {
                                "id": "id_2",
                                "name": "Product B",
                                "bankDisplayName": "Bank A",
                                "expirationDate": "2021-09-24"
                            }
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                    {
                        "id": "3",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "username C",
                        "password": "password",
                        "last_name": "Agent",
                        "first_name": "Name",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 101,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_1",
                            "id_3",
                            "id_4"
                        ],
                        "products": [
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                    {
                        "id": "4",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "username D",
                        "password": "password",
                        "last_name": "Agent",
                        "first_name": "Name",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 111,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_3",
                            "id_4"
                        ],
                        "products": [
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                    {
                        "id": "id_5",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "username E",
                        "password": "password",
                        "last_name": "Agent",
                        "first_name": "Name",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 121,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_1"
                        ],
                        "products": [
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                    {
                        "id": "id_6",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "username F",
                        "password": "password",
                        "last_name": "Agent",
                        "first_name": "Name",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 131,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_1",
                            "id_3",
                            "id_4"
                        ],
                        "products": [
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                    {
                        "id": "id_7",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "username G",
                        "password": "password",
                        "last_name": "Agent",
                        "first_name": "Name",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 141,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_1",
                            "id_3",
                            "id_4"
                        ],
                        "products": [
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                    {
                        "id": "id_8",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "username H",
                        "password": "password",
                        "last_name": "Agent",
                        "first_name": "Name",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 151,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_1",
                            "id_3",
                            "id_4"
                        ],
                        "products": [
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                    {
                        "id": "id_9",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "username I",
                        "password": "password",
                        "last_name": "Agent",
                        "first_name": "Name",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 161,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_1",
                            "id_3",
                            "id_4"
                        ],
                        "products": [
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                    {
                        "id": "id_10",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "username J",
                        "password": "password",
                        "last_name": "Agent",
                        "first_name": "Name",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 171,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_1",
                            "id_3",
                            "id_4"
                        ],
                        "products": [
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                    {
                        "id": "id_11",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "username K",
                        "password": "password",
                        "last_name": "Agent",
                        "first_name": "Name",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 181,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_1",
                            "id_3",
                            "id_4"
                        ],
                        "products": [
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                    {
                        "id": "id_12",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "username L",
                        "password": "password",
                        "last_name": "Agent",
                        "first_name": "Name",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 191,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_1",
                            "id_3",
                            "id_4"
                        ],
                        "products": [
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                    {
                        "id": "id_13",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "username M",
                        "password": "password",
                        "last_name": "Agent",
                        "first_name": "Name",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 201,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_1",
                            "id_3",
                            "id_4"
                        ],
                        "products": [
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                    {
                        "id": "id_14",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "username N",
                        "password": "password",
                        "last_name": "Agent",
                        "first_name": "Name",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 211,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_1",
                            "id_3",
                            "id_4"
                        ],
                        "products": [
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                    {
                        "id": "id_15",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "username O",
                        "password": "password",
                        "last_name": "Agent",
                        "first_name": "Name",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 221,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_1",
                            "id_3",
                            "id_4"
                        ],
                        "products": [
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                    {
                        "id": "id_16",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "username P",
                        "password": "password",
                        "last_name": "Agent",
                        "first_name": "Name",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 231,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_1",
                            "id_3",
                            "id_4"
                        ],
                        "products": [
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                    {
                        "id": "id_17",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "username Q",
                        "password": "password",
                        "last_name": "Agent",
                        "first_name": "Name",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 241,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_1",
                            "id_3",
                            "id_4"
                        ],
                        "products": [
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                    {
                        "id": "id_18",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "username R",
                        "password": "password",
                        "last_name": "Agent",
                        "first_name": "Name",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 251,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_1",
                            "id_3",
                            "id_4"
                        ],
                        "products": [
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                    {
                        "id": "id_19",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "username S",
                        "password": "password",
                        "last_name": "Agent",
                        "first_name": "Name",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 261,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_1",
                            "id_3",
                            "id_4"
                        ],
                        "products": [
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    },
                    {
                        "id": "id_20",
                        "createdAt": "2021-09-24 18:45",
                        "technical": "N",
                        "firm_name": "username T",
                        "password": "password",
                        "last_name": "Agent",
                        "first_name": "Name",
                        "gender": "male",
                        "birth_date": "1990-09-01",
                        "birth_place": "Budapest",
                        "mother_name": "Mother Name",
                        "active": "Y",
                        "taxnumber": "0123456-2-43",
                        "company_reg_number": "0123456789",
                        "mnb_reg_number": "98765432",
                        "phone": "+00 000 0000",
                        "email": "example@mail.hu",
                        "comment": "Lorem ipsum dolor sit amet.",
                        "addresses": [
                            {
                                "id": 271,
                                "address_type": "BASE",
                                "zip_code": "0000",
                                "city": "Budapest",
                                "address": "Fő út 12.",
                                "contactPhone": "+00 000 0000",
                                "contactEmail": "example@mail.hu"
                            }
                        ],
                        "roles": [
                            "id_1",
                            "id_3",
                            "id_4"
                        ],
                        "products": [
                        ],
                        "partner_id": 1,
                        "partner_name": "Partner A"
                    }
                ]
            },
            status: 200
        },
    }
}

