import axios from 'axios';
import store from '@/store';
import DUMMYResponses from '@/commons/DUMMYResponses';
import constants from '@/commons/constants';
import { GVException } from '@/commons/GVException';
import router from '@/router';
import Authentication from '@/commons/Authentication';
import gvApp from '@/main';
//import { isPresent } from '@/components/Utils/utils';


function gvAppLog(...args) {
    if (gvApp === undefined) {
        console.log(args);
    } else {
        gvApp.$log.debug(args);
    }
}

export function isString(value) {
    return typeof value === 'string';
};

export function isNumber(value) {
    return typeof value === 'number';
};

export function isPresent(value) {
    return value !== null && value !== undefined;
};

export function arraySort(value, sortBy) {
    // Example sortBy format
    // sortBy = [{
    //     prop: 'grade',
    //     direction: -1
    // }, {
    //     prop: 'lastName',
    //     direction: 1
    // }];

    value.sort(function (a, b) {
        let i = 0, result = 0;
        while (i < sortBy.length && result === 0) {
            result = sortBy[i].direction * (a[sortBy[i].prop].toString() < b[sortBy[i].prop].toString() ? -1 : (a[sortBy[i].prop].toString() > b[sortBy[i].prop].toString() ? 1 : 0));
            i++;
        }
        return result;
    })
    return value
};

export function apiCaller(method, url, entity = null, needSnackbar = false, response = null, headers = null) {
    // if (gvApp !== undefined){
    //     gvApp.$log.debug("API Caller Url: ", url);
    //     gvApp.$log.debug("API Caller Method: ", method);
    // }
    gvAppLog("API Caller Url: ", url);
    gvAppLog("API Caller Method: ", method);

    let DUMMYData = null;
    switch (method) {
        case "post":
            DUMMYData = DUMMYResponses.DUMMYDataPOST
            break
        case "put":
            DUMMYData = DUMMYResponses.DUMMYDataPUT
            break
        case "delete":
            DUMMYData = DUMMYResponses.DUMMYDataDELETE
            break
        case "get":
        default:
            DUMMYData = DUMMYResponses.DUMMYDataGET
            break
    }
    store.commit('setLoading', true);
    // console.log("DUMMYData: ", DUMMYData);
    // console.log("DUMMYData res: ", DUMMYData[url]);
    // console.log("url: ", url);

    if (response !== null) {
        //gvApp.$log.debug("==[ Called with Response ]==");
        gvAppLog("==[ Called with Response ]==");
        store.commit('setLoading', false);
        return Promise.resolve(response);
    } else if (constants.DUMMY_DATA_HANDLING && DUMMYData.hasOwnProperty(url)) {
        gvAppLog("==[ Found DUMMY Data ]==");
        gvAppLog(DUMMYData[url]);
        store.commit('setLoading', false);
        return Promise.resolve(DUMMYData[url]);
    } else {
        gvAppLog("==[ Not found DUMMY Data ]==");

        // gvApp.$log.debug("==[ Not found DUMMY Data ]==");

        return new Promise((resolve, reject) => {
            store.commit('setAsyncProgess', method + url);
            const access_token = sessionStorage.access_token;
            if (isPresent(access_token) && access_token != "undefined") {
                //axios.defaults.headers.common['Authorization: Bearer '] = access_token;
                axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
            }
            axios[method](url, entity, headers)
                .then(response => {
                    // gvApp.$log.debug("==[ apiCaller success ]==");
                    gvAppLog("==[ apiCaller success ]==");
                    store.commit('clearStatusAdditionalMessage');
                    if (needSnackbar === true) {
                        store.commit('setStatusCode', response.status);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    // gvApp.$log.debug("==[ apiCaller Catch ]==");
                    //gvApp.$log.debug("=========", error.response);
                    gvAppLog("==[ apiCaller Catch ]==");
                    gvAppLog("=========", error.response);
                    store.commit('setSnackbarEnabled', false);

                    const gvError = new GVException(console, error);
                    store.commit('setStatusCode', gvError.getCode());
                    store.commit('setStatusAdditionalMessage', gvError.getMessage());

                    if (gvError.getCode() == constants.NOT_LOGGED_ERROR_CODE) {
                        Authentication.resetLoggedInUser()
                        router.push({ name: "Login" });
                    }

                    store.commit('setSnackbarEnabled', true);
                    reject(gvError);
                })
                .finally(() => {
                    store.commit('setLoading', false);
                    store.commit('setAsyncProgess', method + url);
                });

        });
    }
};

const utils = {
    isString: isString,
    isNumber: isNumber,
    isPresent: isPresent,
    apiCaller: apiCaller,
    arraySort: arraySort
};

export default utils;
