import moment from 'moment-timezone'
import i18n from '@/resources/i18n.js';

const spacePattern = /\s/;
const numberPattern = /^[0-9]+$/;
const letterNumberPattern = /^[0-9a-zA-Z]+$/;
const letterNumberPatternHUN = /^[0-9a-zA-ZáéíóöőúüűÁÉÍÓÖŐÚÜŰ]+$/;
const timePattern = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9]):([0-5][0-9])?$/;
const defaultDateFormat = i18n.t("common.defaultDateFormat.HU");
const defaultDateTimeFormat = i18n.t("common.defaultDateTimeFormat.HU");
import { isPresent } from '@/components/Utils/utils';
import constants from '@/commons/constants';


export default {
//  required: (value) => (isPresent(value) && value.trim() !== '') || i18n.t('validation.required'),
  required: (value) => (value != undefined && value != null && (typeof value == "number" || value.trim() !== '')) || i18n.t('message.required'),
  requiredList: (value) => (value != undefined && value != null && value.length > 0) || i18n.t('message.required'),
  requiredSelect: (value) => (value != undefined && value != null && value !== '') || i18n.t('message.required'),
  notEmpty: (value) => (isPresent(value) && value.length !== 0 || value === false) || i18n.t('validation.notEmpty'),
  notNull: (value) => (value !== null || value === false) || i18n.t('validation.notNull'),
  onlyNumbers: (value) => (value == "" || numberPattern.test(value)) || i18n.t('validation.onlyNumbers'),
  intergerMaxLength: (value) => (value ? value.length <= 10 : true) || i18n.t('validation.toLargeIntegerRange'),
  intergerMaxValue: (value) => (value ? value <= 2147483647 : true) || i18n.t('validation.toLargeIntegerValue'),
  onlyLettersAndNumbers: (value) => letterNumberPattern.test(value) || i18n.t('validation.onlyLettersAndNumbers'),
  onlyLettersAndNumbersHUN: (value) => letterNumberPatternHUN.test(value) || i18n.t('validation.onlyLettersAndNumbersHUN'),
  noSpace: (value) => !spacePattern.test(value) || i18n.t('validation.noSpace'),
  phoneNumberLength: (value) => (!!value && value.length > 7) || i18n.t('validation.phoneNumTooShort'),
  phoneNumberShorthLength: (value) => (!!value && value.length > 6) || i18n.t('validation.phoneNumShorthLengthTooShort'),
  validTime: (value) => timePattern.test(value.padEnd(6, '0')) || i18n.t('validation.invalidTime'),
  validTime4d: (value) => timePattern.test(value.padEnd(4, '0') && moment(value, "HH:mm").isValid()) || i18n.t('validation.invalidTime'),
  validTimeByTimeFormat: (value, timeFormat) => (value != "24:00" && moment(value, timeFormat).isValid()) || i18n.t('validation.invalidTime'),
  timeLength: (value) => (!!value && value.length === 6) || i18n.t('validation.invalidTime'),
  validDate: (value) => moment(value, defaultDateFormat, true).isValid() || i18n.t('validation.invalidDate'),
  validDateTime: (value, dateTimeFormat = defaultDateTimeFormat) => value == "" || (!!value && moment(value, dateTimeFormat, true).isValid()) || i18n.t('validation.invalidDate'),
  dateLength: (value) => (!!value && value.length === 19) || i18n.t('validation.invalidDate'),
  validEnd: (start, end) => (!!start && !!end && ((end == "00:00" && start.localeCompare("24:00") == -1) || (end != "00:00" && start.localeCompare(end) == -1))) || i18n.t('validation.invalidEndTime'),
  validDateEnd: (start, end) => moment(start, defaultDateFormat).isBefore(moment(end, defaultDateFormat)) || i18n.t('validation.invalidEndTime'),
  validDateEndSame: (start, end) => (moment(start, defaultDateFormat).isBefore(moment(end, defaultDateFormat)) || moment(start, defaultDateFormat).isSame(moment(end, defaultDateFormat))) || i18n.t('validation.invalidEndDate'),
  pwMatch: (pw1, pw2) => ( (!!pw1 == false && !!pw2 == false) || pw1 == pw2) || i18n.t('validation.pwsDoesntMatch'),
  pwLength: (value) => (value ? value.length > 7 : true) || i18n.t('validation.pwTooShort'),
  unique: (el, list) =>(!!el && !!list && !list.includes(el)) || i18n.t('validation.unique'),
  uniqueSubId: (el, list) => (!!list && !list.map(e => {return e.gameConnection.dtmfConnection}).includes(el)) || i18n.t('validation.unique'),
  uniquePhoneNumber: (el, list) => (!!el && !!list && !list.find((x) => x.phonenumber == el.phonenumber && x.channel == el.channel)) || i18n.t('validation.uniquePhoneNumber'),
  validPercentage: (value) => (numberPattern.test(value) && value >= 0 && value <= 100 ) || i18n.t('validation.invalidValue'),
  searchTextMinLength: value => (!!value && value.length >= constants.liveSearchMinLength) || i18n.t('validation.searchTextMinLength'),
  checkFixLength: (value, fixLength) => (!!value && value.length == fixLength) || i18n.t('validation.lenghtNotValid'),
  greaterThanZero: (value) => (value == "" || value > 0) || i18n.t('validation.greaterThanZero'),
  lteValue: (value, limitValue) => (value == "" || value <= limitValue) || i18n.t('validation.toLargeIntegerValue'),
  minPassword: value => (!value || (value != undefined && value != null && value.length >= 8)) || i18n.t('validation.minPasswordLength'),

  numberPattern: numberPattern
};