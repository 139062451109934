export default class URLs {
    static AUTH_LOGIN = './api/v1/auth/login';
    static AUTH_LOGOUT = './api/v1/auth/logout';
    static AUTH_DETAILS = './api/v1/auth/my-details';
    static AUTH_USERRIGHTS = './api/v1/user/rights';
    static AUTH_ISAUTHENTICATED = './api/v1/auth/isauthenticated';

    static VERSION_HISTORY = './api/v1/info/version-history';
    static LAST_BUILD_VERSION = './api/v1/info/last-build-version';
    static ENVIRONMENT = './api/v1/info/environment';
    static DATABASE_DATA = "./api/v1/info/database";

    static SYSTEM = './api/v1/system';
    static ROLE = './api/v1/system/roles';
    static SYSTEM_ROLE = './api/v1/system/${systemId}/role';

    static PARTNER = './api/v1/partner';
    static PARTNER_AGENT = './api/v1/partner/${partnerId}/agent';
    static PARTNER_AGENT_ROLE = './api/v1/partner/${partnerId}/${agentId}/role';
    //static PARTNER_AGENT_ADDRESS = './api/v1/partner/${partnerId}/agent/${agentId}/address';
    static PARTNER_AGENT_ADDRESS = './api/v1/address';
    static AGENT_CHANGE_PASSWORD = './api/v1/auth/password';
    static AGENT_UPLOAD_PICTURE = './api/v1/agent/${agentId}/picture';

    static AGENT = './api/v1/agent';
    static AGENT_PRODUCT = './api/v1/partner/${partnerId}/agent/${agentId}/product';
    

    static AGENT_GROUP = './api/v1/agent-group';
    static AGENT_GROUP_SYNC = './api/v1/agent-group/${agentGroupId}/agent';

    static PRODUCT = './api/v1/product';
    
    static BANK = './api/v1/bank';
    static BANK_PRODUCT = './api/v1/bank/${bankId}/product';

    static MESSAGE = './api/v1/messages';
    static MESSAGE_AGENT_INFORMATIONS_LIST = './api/v1/messages/${messageId}/statistics';
    static MESSAGE_DIRECT_USERS = './api/v1/messages/instant/user-list';
    static MESSAGE_DIRECT_MESSAGE_SEND = './api/v1/messages/instant';

    //static EXAMPLE = "./api/v1/example";
} 