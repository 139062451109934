import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const locales = ["hu"];
const localeDefinitions = {};
const localeToPath = locale => "./" + locale;
const resources = {};

for (let locale of locales) {
  resources[locale] = require(localeToPath(locale) + ".json");
  localeDefinitions[locale] = localeToPath(locale);
}

const i18n = new VueI18n({
  locale: "hu",
  fallbackLocale: "hu",
  messages: resources
});

if (module.hot) {
  module.hot.accept(Object.values(localeDefinitions), function() {
    localeDefinitions.forEach((locale, path) => {
      i18n.setLocaleMessage(locale, require(path + ".json").default);
    });
  });
}

export default i18n;
