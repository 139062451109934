import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store/index'
import Authentication from '@/commons/Authentication';


Vue.use(VueRouter)


const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next()
        return
    }
    next('/login')
}

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated && sessionStorage.access_token && sessionStorage.access_token != "") {
        next()
        return
    } else {
        console.debug("Try isAuthenticated");
        Authentication.isAuthenticated()
            .then((resp) => {
                console.debug("Response isAuthenticated: ", resp)
                if (resp.data.data.authenticated == true) {
                    sessionStorage.setItem('access_token', resp.data.data.access_token);
                    Authentication.getMyDetails()
                        .then(() => {
                            Authentication.getUserRights()
                                .then(() => {
                                    next();
                                    return
                                })
                                .catch(error => {
                                    console.debug(error);
                                    sessionStorage.removeItem('access_token')
                                    next('/login');
                                });
                            return
                        })
                        .catch(error => {
                            console.debug(error);
                            sessionStorage.removeItem('access_token')
                            next('/login');
                        });
                } else {
                    sessionStorage.removeItem('access_token')
                    next('/login');
                }
                return
            })
            .catch(error => {
                console.debug(error);
                sessionStorage.removeItem('access_token')
                next('/login');
            });
    }
}


const routes = [
    {
        path: '/home',
        name: 'Home',
        beforeEnter: ifAuthenticated,
        component: () => import('../views/Home.vue')
    },

    {
        path: '/login',
        name: 'Login',
        beforeEnter: ifNotAuthenticated,
        component: () => import('../Login.vue')
    },

    {
        path: '/partners',
        name: 'Partners',
        beforeEnter: ifAuthenticated,
        component: () => import('../views/Partners.vue')
    },
    {
        path: '/agents',
        name: 'Agents',
        beforeEnter: ifAuthenticated,
        component: () => import('../views/Agents.vue')
    },
    {
        path: '/agentGroups',
        name: 'AgentGroups',
        beforeEnter: ifAuthenticated,
        component: () => import('../views/AgentGroups.vue')
    },
    {
        path: '/banks',
        name: 'Banks',
        beforeEnter: ifAuthenticated,
        component: () => import('../views/Banks.vue')
    },
    {
        path: '/products',
        name: 'Products',
        beforeEnter: ifAuthenticated,
        component: () => import('../views/Products.vue')
    },
    {
        path: '/messages',
        name: 'Messages',
        beforeEnter: ifAuthenticated,
        component: () => import('../views/Messages.vue')
    },
]

const router = new VueRouter({
    routes
})

// router.beforeEach((to, from, next) => {
//   var realPaths = router.options.routes.map(el => el.path);
//   if (!realPaths.includes(to.path)) {
//     next('/login');
//   } else {
//     next();
//   }
// });

export default router
