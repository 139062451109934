import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        statusCode: null,
        statusAdditionalMessage: null,
        myLoading: false,
        asyncProgess: [],
        snackbarEnabled: false,

        loggedInUserObj: null,
        loggedInUserId: null,
        loggedInUserUsername: null,
        loggedInUserRights: [],

        frontendVersion: process.env.VUE_APP_PACKAGE_VERSION,
        backendVersion: null,
        backendLastBuildTime: null,
        environmentData: null,
    },
    mutations: {
        setLoading(state, payload) {
            state.myLoading = payload;
        },
        setAsyncProgess(state, key) {
            let idx = state.asyncProgess.indexOf(key);
            if (idx > -1) {
                this.state.asyncProgess.splice(idx, 1);
            } else {
                this.state.asyncProgess.push(key);
            }
        },
        setSnackbarEnabled(state, payload) {
            state.snackbarEnabled = payload;
        },
        setStatusAdditionalMessage(state, payload) {
            state.statusAdditionalMessage = payload;
        },

        setLoggedInUserRights(state, payload) {
            state.loggedInUserRights = payload;
        },
        setStatusCode(state, payload) {
            state.statusCode = payload;
        },
        clearStatusCode(state) {
            state.statusCode = null;
        },
        clearStatusAdditionalMessage(state) {
            state.statusAdditionalMessage = null;
        },

        setLoggedInUserObj(state, payload) {
            state.loggedInUserObj = payload;
        },
        setLoggedInUserUsername(state, payload) {
            state.loggedInUserUsername = payload;
        },
        setBackendVersion(state, payload) {
            state.backendVersion = payload;
        },
        setBackendLastBuildTime(state, payload) {
            state.backendLastBuildTime = payload;
        },
        setEnvironmentData(state, payload) {
            state.environmentData = payload;
        },
    },
    actions: {
    },
    modules: {
    },
    getters: {
        asyncProgess: state => state.asyncProgess,
        statusCode: state => state.statusCode,
        statusAdditionalMessage: state => state.statusAdditionalMessage,
        myLoading: state => state.myLoading,
        snackbarEnabled: state => state.snackbarEnabled,
        getLoggedInUserObj: state => state.loggedInUserObj,
        getLoggedInUserId: state => state.loggedInUserId,
        getLoggedInUserUsername: state => state.loggedInUserUsername,
        getLoggedInUserRights: state => state.loggedInUserRights,

        appFrontendVersion: state => state.frontendVersion,
        appBackendVersion: state => state.backendVersion,
        appBackendLastBuildTime: state => state.backendLastBuildTime,
        environmentData: state => state.environmentData,
    }
})
