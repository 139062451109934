export default {
	FRONTEND_VERSION_HISTORY: [
		{
			release_version: "1.1.1 (2021-09-16)",
			release_note: [
				{
					task_type: "feature",
					task_description: `Adatbázis kapcsolat megjelenítése a fejlécben (891)`
				},
				{
					task_type: "bug",
					task_description: `Táblázatok oszlopszélességeinek optimalizálása (858)`
				},
			]
		},
		{
			release_version: "1.1.0 (2021-03-31)",
			release_note: [
				{
					task_type: "feature",
					task_description: `Útvonal sablonok paraméterezhetőségének bevezetése, hogy külső rendszerek felől is lehessen sablonokat használni. (770)`
				},
				{
					task_type: "feature",
					task_description: `Új mezők az útvonal sablon alapadatok szerkesztésénél: Sablon neve, Külső rendszer (772)`
				},
				{
					task_type: "feature",
					task_description: `Úvonal sablon információk megjelenítése a sablonok lista felületen (787)`
				},
				{
					task_type: "feature",
					task_description: `"Mindig inaktív" státusz bevezetése a színes számok aktív időszakainál. Az egyes számok státuszát már útvonalanként láthatjuk. (778)`
				},
			]
		},
		{
			release_version: "1.0.3 (2021-02-09)",
			release_note: [
				{
					task_type: "feature",
					task_description: `Aktuálisan használandó rendszer megjelenítése (745)`
				},
				{
					task_type: "feature",
					task_description: `CMD fájl szolgáltatásoknál feltöltésnél legyen kiterjesztés beállítva (754)`
				},
				{
					task_type: "bug",
					task_description: `Bemondások menüpontban a táblázat a Név és Leírás oszlopot ABC sorrendbe rendezése (690)`
				},
			]
		},
		{
			release_version: "1.0.2 (2021-01-22)",
			release_note: [
				{
					task_type: "bug",
					task_description: `Nemzetközi zöld számok IC és OG menüknél nem lehet feltölteni 2x állományt (748)`
				},
			]
		},
		{
			release_version: "1.0.1 (2020-11-19)",
			release_note: [
				{
					task_type: "feature",
					task_description: `Javítások.`
				},
			]
		},
		{
			release_version: "1.0.0 (2019-12-06)",
			release_note: [
				{
					task_type: "feature",
					task_description: `Első hivatalos verzió.`
				},
			]
		},
	]
}

