import i18n from '@/resources/i18n.js';

export default {
    DUMMY_DATA_HANDLING: true,
    timezoneHU: 'Europe/Budapest',
    HTTP_METHODS: {
        GET: "get",
        POST: "post",
        PUT: "put",
        DELETE: "delete"
    },
    NRG_RIGHTS: {
        ROVID_EDIT: "ROVID_EDIT",
        ROVID_VIEW: "ROVID_VIEW",
        GTS_FINALIZE: "GTS_FINALIZE",
        TAVSZAVAZAS_FINALIZE: "TAVSZAVAZAS_FINALIZE",
        ROVID_FINALIZE: "ROVID_FINALIZE",
        CONFIGURATION_OTHER_EDIT: "CONFIGURATION-OTHER_EDIT",
        CONFIGURATION_OTHER_VIEW: "CONFIGURATION-OTHER_VIEW",
        HIRDETOI_FINALIZE: "HIRDETOI_FINALIZE",
        EMELTDIJAS_EDIT: "EMELTDIJAS_EDIT",
        EMELTDIJAS_VIEW: "EMELTDIJAS_VIEW",
    },
    NRG_RIGHTS_EDIT_SUFFIX: "_EDIT",
    NRG_RIGHTS_FINALIZE_SUFFIX: "_FINALIZE",
    NRG_RIGHTS_IMPORT_SUFFIX: "_IMPORT",
    NRG_RIGHTS_TEMPLATEIMPORT_SUFFIX: "_TEMPLATEIMPORT",
    
    liveSearchMinLength: 3,

    DAYS: {
        0: { text: i18n.t("common.weekdays_short.sunday"), longtext: i18n.t("common.weekdays_long.sunday"), value: "07" },
        1: { text: i18n.t("common.weekdays_short.monday"), longtext: i18n.t("common.weekdays_long.monday"), value: "01" },
        2: { text: i18n.t("common.weekdays_short.tuesday"), longtext: i18n.t("common.weekdays_long.tuesday"), value: "02" },
        3: { text: i18n.t("common.weekdays_short.wednesday"), longtext: i18n.t("common.weekdays_long.wednesday"), value: "03" },
        4: { text: i18n.t("common.weekdays_short.thursday"), longtext: i18n.t("common.weekdays_long.thursday"), value: "04" },
        5: { text: i18n.t("common.weekdays_short.friday"), longtext: i18n.t("common.weekdays_long.friday"), value: "05" },
        6: { text: i18n.t("common.weekdays_short.saturday"), longtext: i18n.t("common.weekdays_long.saturday"), value: "06" },
        7: { text: i18n.t("common.weekdays_short.sunday"), longtext: i18n.t("common.weekdays_long.sunday"), value: "07" },
    },
    SHORT_DAYS: {
        "mon": { text: i18n.t("common.weekdays_short.monday"), value: "01" },
        "tue": { text: i18n.t("common.weekdays_short.tuesday"), value: "02" },
        "wed": { text: i18n.t("common.weekdays_short.wednesday"), value: "03" },
        "thu": { text: i18n.t("common.weekdays_short.thursday"), value: "04" },
        "fri": { text: i18n.t("common.weekdays_short.friday"), value: "05" },
        "sat": { text: i18n.t("common.weekdays_short.saturday"), value: "06" },
        "sun": { text: i18n.t("common.weekdays_short.sunday"), value: "07" },
    },
    MONTHS: [
        { text: i18n.t("month.january"), value: "01" },
        { text: i18n.t("month.february"), value: "02" },
        { text: i18n.t("month.march"), value: "03" },
        { text: i18n.t("month.april"), value: "04" },
        { text: i18n.t("month.may"), value: "05" },
        { text: i18n.t("month.june"), value: "06" },
        { text: i18n.t("month.july"), value: "07" },
        { text: i18n.t("month.august"), value: "08" },
        { text: i18n.t("month.september"), value: "09" },
        { text: i18n.t("month.october"), value: "10" },
        { text: i18n.t("month.november"), value: "11" },
        { text: i18n.t("month.december"), value: "12" },
    ],

    NOT_LOGGED_ERROR_CODE: 1001,


    CSV_FILE_ROW_SEPARATOR: "\n",
    CSV_FILE_CELL_SEPARATOR: ";",


    ADDRESS_TYPES: [
        { key: "BASE" },
        { key: "SITE" },
        { key: "POST" },
        { key: "BILL" },
    ],

    MESSAGES_STATUSES:[
        { key: "EDIT" },
        { key: "SENDING" },
//        { key: "POSTED" },
    ]

};

