import URLs from '@/urls';
import store from "@/store";
import router from '@/router';
import utils from '@/components/Utils/utils';
import constants from '@/commons/constants';
import gvApp from '@/main';


export default {

    login(credentials) {
        return new Promise((resolve, reject) => {
            utils.apiCaller(constants.HTTP_METHODS.POST, URLs.AUTH_LOGIN, credentials)
                .then(resp => {
                    gvApp.$log.debug("LOGIN Endpoint: SUCCESS. Response: ", resp);
                    // if (resp.data.data.success) {
                    if (resp.data.data.access_token) {
                        gvApp.$log.debug("LOGGED");
                        const access_token = resp.data.data.access_token;
                        sessionStorage.setItem('access_token', access_token) // store the token in sessionStorage
                        sessionStorage.setItem('authenticated', true);
                    } else {
                        this.resetLoggedInUser();
                        gvApp.$log.debug("LOGIN FAILED");
                        //throw new Error('LOGIN FAILED');
                    }
                    resolve(resp.data.data);
                })
                .catch(err => {
                    this.resetLoggedInUser();
                    gvApp.$log.debug("LOGIN FAILED");
                    sessionStorage.removeItem('access_token')
                    store.commit('setStatusCode', 'loginError');
                    reject(err);
                })
                .finally(() => {
                    store.commit('setSnackbarEnabled', false);
                });
        });
    },

    logout() {
        return new Promise((resolve, reject) => {
            utils.apiCaller(constants.HTTP_METHODS.POST, URLs.AUTH_LOGOUT)
                .then((res) => {
                    sessionStorage.removeItem('access_token')
                })
                .catch((err) => {
                    gvApp.$log.error("LOGOUT FAILED");
                    gvApp.$log.error(err);
                    reject(err);
                })
                .finally(() => {
                    this.resetLoggedInUser();
                    resolve();
                });
        });
    },

    getMyDetails() {
        return new Promise((resolve, reject) => {
            utils.apiCaller(constants.HTTP_METHODS.GET, URLs.AUTH_DETAILS)
                .then((resp) => {
                    if (resp.status == "200") {
                        store.commit('setLoggedInUserUsername', resp.data.data.username);

                        sessionStorage.setItem('loggedInUserUsername', resp.data.data.username);
                        store.commit('setLoggedInUserObj', resp.data.data);
                        sessionStorage.setItem('authenticated', true);
                    } else {
                        this.resetLoggedInUser();
                    }
                    resolve();
                })
                .catch((err) => {
                    gvApp.$log.error(err);
                    this.resetLoggedInUser();
                    reject(err);
                })
        });
    },

    getUserRights() {
        return new Promise((resolve, reject) => {
            const url = URLs.AUTH_USERRIGHTS;
            utils.apiCaller(constants.HTTP_METHODS.GET, url)
                .then((resp) => {
                    if (resp.status == "200") {
                        if (resp.data.data.rights.length == 0) {
                            //throw new Error('YOU HAVE NO RIGHTS');
                        }
                        resolve(resp.data.data.rights);
                        gvApp.$log.debug("Response User Rights: ", resp.data.data.rights);
                        store.commit('setLoggedInUserRights', resp.data.data.rights);
                    } else {
                        this.resetLoggedInUser();
                    }
                    resolve();
                })
                .catch((err) => {
                    gvApp.$log.error(err);
                    this.resetLoggedInUser();
                    reject(err);
                })
        });
    },

    isAuthenticated() {
        return new Promise((resolve, reject) => {
            utils.apiCaller(constants.HTTP_METHODS.GET, URLs.AUTH_ISAUTHENTICATED)
                .then((resp) => {
                    gvApp.$log.debug("Response IsAuthenticated: ", resp);
                    if (resp.status == "200") {
                        if (resp.data.data.authenticated == false)
                            sessionStorage.setItem('authenticated', false);
                        resolve(resp);
                    } else {
                        this.resetLoggedInUser();
                    }
                    resolve(resp);
                })
                .catch((err) => {
                    gvApp.$log.error(err);
                    this.resetLoggedInUser();
                    reject(err);
                })
        });
    },

    resetLoggedInUser() {
        store.commit('setLoggedInUserUsername', null);
        store.commit('setLoggedInUserRights', []);
        store.commit('setLoggedInUserObj', null);
        sessionStorage.setItem('loggedInUserUsername', null);
        sessionStorage.setItem('authenticated', false);
    },

    hasRight(rights) {
        if (store.getters.getLoggedInUserObj && rights !== null && rights) {
            let userRights = store.getters.getLoggedInUserRights;

            return ((userRights.filter(userRight => rights.includes(userRight)).length > 0) ? true : false)
        }
        return false;
    }
};
