import { isPresent } from '@/components/Utils/utils';

export default {
    data() {
        return {
            isMobile: false,
            styleProps: {
                windowWidth: "",
                windowHeight: "",
                pageOffset: 0,
                dialogOffset: 300,
                fullscreenDialogOffset: 64,
            },
            headerProps: {
                sortByText: this.$i18n.t('common.sortBy')
            },

            dialog: {
                details: false,
                edit: false,
                delete: false
            },

            snackbar: false,
            selectedEntity: {},
            entityHandler: null,
            allowedButtons: []
        };
    },

    created() {
        this.styleProps.windowWidth = window.innerWidth;
        this.styleProps.windowHeight = window.innerHeight;
        this.detectMobile();
        this.fetchEntities();
    },

    mounted() {
        window.addEventListener('resize', () => {
            this.styleProps.windowWidth = window.innerWidth;
            this.styleProps.windowHeight = window.innerHeight;
        });
    },

    computed: {
        computedWidth() {
            return this.styleProps.windowWidth;
        },
        computedHeight() {
            return this.styleProps.windowHeight;
        },
        computedPageHeight() {
            return this.styleProps.windowHeight - this.styleProps.pageOffset;
        },
        computedDialogHeight() {
            return this.styleProps.windowHeight - this.styleProps.dialogOffset;
        },
        computedFullScreenDialogHeight() {
            return this.styleProps.windowHeight - this.styleProps.fullscreenDialogOffset;
        },

        isMiniLogin() {
            if (this.computedWidth < 620) {
                return true
            } else {
                return false
            }
        },

        isMdScreen() {
            if (this.computedWidth < 832) {
                return true
            } else {
                return false
            }
        },

        isMiniForm() {
            if (this.computedWidth < 420) {
                return true
            } else {
                return false
            }
        },

        isMiniTable() {
            if (this.computedWidth < 600) {
                return true
            } else {
                return false
            }
        },
        tableOffset() {
            if (this.isMiniTable) {
                return 124;
            } else {
                return 72;
            }
        },

        statusCode() {
            return this.$store.getters.statusCode ? this.$store.getters.statusCode.toString() : 'TODO';
        },
        loadedEntities() {
            return this.entityHandler.entityItems;
        },
        itemForDelete() {
            let res = isPresent(this.selectedEntity) && isPresent(this.selectedEntity.name) ? this.selectedEntity.name : '';
            return res;
        }
    },

    methods: {
        detectMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        loadPage(path) {
            if (!!path) {
                this.$router.push(path, () => { });
            }
        },
        fetchEntities() {
            if (this.entityHandler) {
                this.entityHandler.fetchEntities();
            }
        },
        closeDialog() {
            this.dialog.details = false;
            this.dialog.edit = false;
            this.dialog.delete = false;
            this.clearDialog();
        },
        clearDialog() {

        },
        openDialogNew() {
            this.clearDialog();
            this.dialog.edit = false;
            this.dialog.details = true;
        },
        openDialogDetails(entity) {
            this.dialog.edit = true;
            this.dialog.details = true;
            this.selectedEntity = entity;
        },
        openDialogDelete(entity) {
            this.dialog.delete = true;
            this.dialog.edit = false;
            this.dialog.details = false;
            this.selectedEntity = entity;
        },
        deleteSelectedEntity() {
            this.$log.debug('DataComponentBase of DataComponentBase - deleteSelectedEntity');
            this.$log.debug(this.selectedEntity);

            this.entityHandler
                .deleteEntity(this.selectedEntity)
                .then((response) => {
                    this.$store.commit('setStatusCode', response.status);
                    this.$store.commit('setSnackbarEnabled', true);
                })
                .catch((error) => {
                    this.$log.error(error);
                    this.$store.commit('setStatusCode', error.getCode());
                })
                .finally(() => {
                    this.closeDialog();
                });
        },
        hasRight(right = "") {
            return Authentication.hasRight(right);
        },
        hasEditRight() {
            let editRights = this.needRoles.filter(role => role.endsWith(this.$constants.NRG_RIGHTS_EDIT_SUFFIX));
            let hasEditRight = false;
            editRights.forEach(right => {
                hasEditRight = this.hasRight(right);
                if (hasEditRight) return;
            });
            return hasEditRight;
        },
        removeNotAllowedButtonByKey(btnKey) {
            if (this.allowedButtons.indexOf(btnKey) > -1) this.allowedButtons.splice(this.allowedButtons.indexOf(btnKey), 1);
        },
        addAllowedButtonByKey(btnKey) {
            this.allowedButtons.push(btnKey);
        },
        needRolesForMenu(name) {
            return this.$router.options.routes.find(el => el.name == name).meta.requiredRoles;
        },
        createEntityObj() { },
    },

    watch: {
    }
};
