export class GVException {
    _exception = null;
    _code = null;
    _message = null;
    _trace = null;

    constructor(log, e) {
        this.log = log;
        this._exception = e;
        
        this._code = this._exception.response.status;
        this._message = this._exception.response.statusText;

        if (this._exception.response 
            && this._exception.response.hasOwnProperty('data')
            && this._exception.response.data.error.hasOwnProperty('code')) {
            this._code = this._exception.response.data.error.code;
            this._message = this._exception.response.data.error.message;
        } else {
            this._code = this._exception.response.status;
            this._message = this._exception.response.statusText;
            this._trace = this._exception.trace;
        }
    }

    getException() {
        return this._exception;
    }

    getCode() {
        return this._code;
    }

    getMessage() {
        return this._message;
    }

    getTrace() {
        return this._trace;
    }
}
