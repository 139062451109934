import URLs from '@/urls';
import { EntityBase } from '@/commons/EntityBase.js';
import { isPresent } from '@/components/Utils/utils';
import constants from '@/commons/constants';
import utils from '@/components/Utils/utils';


export class AgentEntity extends EntityBase {
    apiURLs = {
        listAll: URLs.AGENT,
        listAllOfPartner: URLs.PARTNER_AGENT,
        save: URLs.PARTNER_AGENT,
        update: URLs.PARTNER_AGENT,
        delete: URLs.PARTNER_AGENT,
        productList: URLs.AGENT_PRODUCT,
        roles: URLs.PARTNER_AGENT_ROLE,
        getEntity: URLs.PARTNER_AGENT,
        changePassword: URLs.AGENT_CHANGE_PASSWORD,
        uploadPicture: URLs.AGENT_UPLOAD_PICTURE
    };

    partnerId = null;

    constructor(log, store, partnerId = null) {
        super(log, store);
        this.partnerId = partnerId;
    }

    setPartnerId(partnerId) {
        this.log.debug("=============== setPartnerId ================", partnerId);
        this.partnerId = partnerId;
    }

    createEntityObj(entity = null) {
        var md5 = require('md5');
        let returnObj = {};

        returnObj.id = isPresent(entity) && isPresent(entity.id) ? entity.id : '';
        returnObj.partner_id = isPresent(entity) && isPresent(entity.partner_id) ? entity.partner_id : this.partnerId;
        returnObj.technical = isPresent(entity) && isPresent(entity.technical) ? entity.technical : 'N';
        returnObj.last_name = isPresent(entity) && isPresent(entity.last_name) ? entity.last_name : '';
        returnObj.first_name = isPresent(entity) && isPresent(entity.first_name) ? entity.first_name : '';
        returnObj.picture = isPresent(entity) && isPresent(entity.picture) ? entity.picture : '';
        returnObj.image = isPresent(entity) && isPresent(entity.image) ? entity.image : "";
        returnObj.active = isPresent(entity) && isPresent(entity.active) ? entity.active : 'Y';
        returnObj.firm_name = isPresent(entity) && isPresent(entity.firm_name) ? entity.firm_name : '';
        returnObj.bank_account_number = isPresent(entity) && isPresent(entity.bank_account_number) ? entity.bank_account_number : '';
        returnObj.tax_number = isPresent(entity) && isPresent(entity.tax_number) ? entity.tax_number : '';
        returnObj.company_reg_number = isPresent(entity) && isPresent(entity.company_reg_number) ? entity.company_reg_number : '';
        returnObj.mnb_reg_number = isPresent(entity) && isPresent(entity.mnb_reg_number) ? entity.mnb_reg_number : '';
        returnObj.birth_date = isPresent(entity) && isPresent(entity.birth_date) ? entity.birth_date : '';
        returnObj.birth_place = isPresent(entity) && isPresent(entity.birth_place) ? entity.birth_place : '';
        returnObj.gender = isPresent(entity) && isPresent(entity.gender) ? entity.gender : '';
        returnObj.mother_name = isPresent(entity) && isPresent(entity.mother_name) ? entity.mother_name : '';
        returnObj.phone = isPresent(entity) && isPresent(entity.phone) ? entity.phone : '';
        returnObj.email = isPresent(entity) && isPresent(entity.email) ? entity.email : '';
        returnObj.password = isPresent(entity) && isPresent(entity.password) && entity.password != "" ? md5(entity.password) : null;
        returnObj.password_confirmation = isPresent(entity) && isPresent(entity.password_confirmation) && entity.password_confirmation != "" ? md5(entity.password_confirmation) : null;

        returnObj.comment = isPresent(entity) && isPresent(entity.comment) ? entity.comment : '';
        returnObj.activation_token = isPresent(entity) && isPresent(entity.activation_token) ? entity.activation_token : '';
        returnObj.created_at = isPresent(entity) && isPresent(entity.created_at) ? entity.created_at : '';
        returnObj.modified_at = isPresent(entity) && isPresent(entity.modified_at) ? entity.modified_at : '';
        returnObj.modifiedby = isPresent(entity) && isPresent(entity.modifiedby) ? entity.modifiedby : '';
        returnObj.deleted_at = isPresent(entity) && isPresent(entity.deleted_at) ? entity.deleted_at : '';

        returnObj.addresses = isPresent(entity) && isPresent(entity.addresses) ? entity.addresses : [];
        returnObj.roles = isPresent(entity) && isPresent(entity.roles) ? entity.roles : [];
        returnObj.products = isPresent(entity) && isPresent(entity.products) ? entity.products : [];
        returnObj.devices = isPresent(entity) && isPresent(entity.devices) && entity.devices != null ? entity.devices : [{ deviceKey: 0, device_id: "", registration_date: "", token: "" , device_info: {name: "", model: "", system_name: "", system_version: ""}}];
        returnObj.image_delete = isPresent(entity) && isPresent(entity.image_delete) ? entity.image_delete : false;
        //returnObj.mobileData = {device_id: "", registration_at: "", device_info: "", token: ""};

        return returnObj;
    }

    createEntityObjEditAndNew(entity = null) {
        let createEntityObj = this.createEntityObj(entity);
        createEntityObj.products = entity.products.filter(el => el.examValidity != "").map(el => ({ id: el.id, expiration_date: el.examValidity }))

        if (createEntityObj.image_delete === true){
            createEntityObj.picture = "";
            createEntityObj.image = "";
        }
        delete createEntityObj["image_delete"];
        delete createEntityObj["devices"];
        return createEntityObj;
    }

    get(entity, idFieldName = 'id') {
        this.log.debug('EntityBase of ' + this.constructor.name + ' - get: ', entity);
        let url = this.apiURLs.getEntity;
        //fixme: nem kell, h ha lesz vegpont amivel dierkben lekerdezheto 1 agent
        if (isPresent(entity.partner_id)) {
            let partnerId = entity.partner_id;
            url = eval(`\`${this.apiURLs.getEntity}\``);
        }
        return utils.apiCaller(constants.HTTP_METHODS.GET, url + "/" + entity[idFieldName])
    }

    save(entity) {
        this.log.debug('EntityBase of ' + this.constructor.name + ' - save');
        let url = this.apiURLs.save;
        //fixme: nem kell, h ha lesz vegpont amivel dierkben lekerdezheto 1 agent
        if (isPresent(entity.partner_id)) {
            let partnerId = entity.partner_id;
            url = eval(`\`${this.apiURLs.save}\``);
        }

        return utils.apiCaller(constants.HTTP_METHODS.POST, url, entity)
    }

    uploadPicture(entity) {
        this.log.debug('EntityBase of ' + this.constructor.name + ' - uploadPicture');
        let url = this.apiURLs.uploadPicture;

        let formData = new FormData();
        formData.append("agent_image", entity.picture);

        let formDataHeaders = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };

        if (isPresent(entity.id)) {
            let agentId = entity.id;
            url = eval(`\`${url}\``);
        }
        console.log(constants.HTTP_METHODS.POST, url, formData, formDataHeaders);
        return utils.apiCaller(constants.HTTP_METHODS.POST, url, formData, false, null, formDataHeaders)
    }

    update(entity) {
        this.log.debug('EntityBase of ' + this.constructor.name + ' - update');
        let url = this.apiURLs.update;
        //fixme: nem kell, h ha lesz vegpont amivel dierkben lekerdezheto 1 agent
        if (isPresent(entity.partner_id)) {
            let partnerId = entity.partner_id;
            url = eval(`\`${this.apiURLs.update}\``);
        }
        url = url + "/" + entity.id;
        return utils.apiCaller(constants.HTTP_METHODS.PUT, url, entity)
    }

    delete(entity) {
        this.log.debug('EntityBase of ' + this.constructor.name + ' - delete');
        this.log.debug(entity);
        let url = this.apiURLs.delete;
        //fixme: nem kell, h ha lesz vegpont amivel dierkben lekerdezheto 1 agent
        if (isPresent(entity.partner_id)) {
            let partnerId = entity.partner_id;
            url = eval(`\`${this.apiURLs.delete}\``);
        }
        url = url + "/" + entity.id;
        return utils.apiCaller(constants.HTTP_METHODS.DELETE, url)
    }

    listAll() {
        let url = this.apiURLs.listAll;
        if (isPresent(this.partnerId) && this.partnerId != '') {
            let partnerId = this.partnerId;
            url = eval(`\`${this.apiURLs.listAllOfPartner}\``);
            this.log.debug('EntityBase of ' + this.constructor.name + ' - listall [ ' + url + ' ]');
            return utils.apiCaller(constants.HTTP_METHODS.GET, url, null, false);
        } else if (this.partnerId == "") {
            return utils.apiCaller(constants.HTTP_METHODS.GET, url, null, false);
        } else {
            return utils.apiCaller(constants.HTTP_METHODS.GET, url, null, false, {data: {data: []}});
        }

        // return utils.apiCaller(constants.HTTP_METHODS.GET, url, null, false);
    }

    productList(entity, idFieldName = 'id') {
        let agentId = entity.id;
        let partnerId = entity.partner_id;
        if (agentId && partnerId) {
            let url = eval(`\`${this.apiURLs.productList}\``);
            this.log.debug('EntityBase of ' + this.constructor.name + ' - productList [ ' + url + ' ]');
            return utils.apiCaller(constants.HTTP_METHODS.GET, url);
        } else {
            let url = eval(`\`${this.apiURLs.productList}\``);
            this.log.debug('EntityBase of ' + this.constructor.name + ' - productList [ ' + url + ' ]');
            return utils.apiCaller(constants.HTTP_METHODS.GET, url, null, false, {data: {data: []}});
        }
    }

    roles(entity, idFieldName = 'id') {
        let agentId = entity.id;
        let partnerId = this.partnerId;
        let url = eval(`\`${this.apiURLs.roles}\``);

        this.log.debug('EntityBase of ' + this.constructor.name + ' - roles [ ' + url + ' ]');
        return utils.apiCaller(constants.HTTP_METHODS.GET, url);
    }

    changePassword(entity) {
        this.log.debug('EntityBase of ' + this.constructor.name + ' - changepassword');
        let url = this.apiURLs.changePassword;
        return utils.apiCaller(constants.HTTP_METHODS.PUT, url, entity)
    }
}
