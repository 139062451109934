import moment from 'moment-timezone'
import i18n from '@/resources/i18n.js';
import constants from '@/commons/constants';

export default {
  formatCustomers(customers) {
    if(customers && customers.length > 1) {
      return "ALL";
    } else if(customers.length == 1) {
      return customers[0].customerName;
    } else {
      return "";
    }
  },
  formatArray(array) {
    return array.toString();
  },
  formatDate(dateToFormat, dateFormat = i18n.t("common.onlyDateDateFormat.HU")) {
    moment().utcOffset(0);
    let utcDate = moment.utc(dateToFormat); // fixme: 2h elteressel adja vissza ez ertekeket
    return dateToFormat !== null ? utcDate.clone().format(dateFormat) : "";
  },
  formatTime(timeToFormat, inTimeFormat="HHmm", outDateFormat = i18n.t("common.shortTimeFormat.HU")) {
    let utcDate = new moment(timeToFormat, inTimeFormat); // fixme: 2h elteressel adja vissza ez ertekeket
    return utcDate.clone().tz(i18n.t("common.timezone.HU")).format(outDateFormat);
  },
  formatToDateTime(dateToFormat) {
    moment().utcOffset(0);
    let utcDate = moment.utc(dateToFormat); // fixme: 2h elteressel adja vissza ez ertekeket
    return dateToFormat !== null ? utcDate.clone().format(i18n.t("common.defaultDateTimeFormat.HU")) : "";
  },
  formatTimeStamp(timestamp) {
    return timestamp.slice(0, 2) + ":" + timestamp.slice(2, 4) + ":" + timestamp.slice(4);
  },
  activeTrueFalse(item) {
    return item ? i18n.t("common.active") : i18n.t("common.inactive");
  },
  formatterTrueFalse(item) {
    return item ? i18n.t("common.true") : i18n.t("common.false");
  },
  formatterValidateTrueFalse(item) {
      let result;
        switch (item) {
            case true:
                result = i18n.t("common.validated.true");
            break;
            case false:
                result = i18n.t("common.validated.false");
            break;
            default:
                result = '';
            break;
        }
        return result;
  },
  formatDayLabel(dayNumber) {
    return (constants.DAYS[dayNumber].text);
  },
  formatDayLongLabel(dayNumber) {
    return (constants.DAYS[dayNumber].longtext);
  },
  formatDayShortLabel(dayShortLabel) {
    return constants.SHORT_DAYS[dayShortLabel].text;
  },
  convertWeekDays(weekdays) {
    let converted = [];
    let sorted = weekdays.slice().sort((a, b) => a - b);
    sorted.forEach(el => {
      switch (parseInt(el)) {
        case 1:
          converted.push(i18n.t("common.weekdays_short.monday"));
          break;
        case 2:
          converted.push(i18n.t("common.weekdays_short.tuesday"));
          break;
        case 3:
          converted.push(i18n.t("common.weekdays_short.wednesday"));
          break;
        case 4:
          converted.push(i18n.t("common.weekdays_short.thursday"));
          break;
        case 5:
          converted.push(i18n.t("common.weekdays_short.friday"));
          break;
        case 6:
          converted.push(i18n.t("common.weekdays_short.saturday"));
          break;
        case 7:
          converted.push(i18n.t("common.weekdays_short.sunday"));
          break;
        default:
          break;
      }
    })
    return converted.join(", ");
  },
  calendarDateType(item) {
    const dayTypeName = constants.CALENDAR_DAYTYPE.find(el => el.key == item);

    if (dayTypeName) {
      return dayTypeName["label"];
    }
    else {
      return item;
    }
  },
  routeStatus(statusKey){
    return constants.ROUTE_STATUSES.find(item => item.key == statusKey).value;
  },
  formatAnnouncementSector(sectorId) {
    return i18n.t(constants.ANNOUNCEMENT_SECTOR.find(item => item.key == sectorId).value);
  },
  formatServiceById(serviceId) {
    let key = Object.keys(constants.NRG_SERVICES).find(key => constants.NRG_SERVICES[key] == serviceId);
    return i18n.t("submenu." + key);
  },
};