import URLs from '@/urls';
import utils from '@/components/Utils/utils';
import constants from '@/commons/constants';
import store from "@/store";
import releaseNotes from "@/releaseNotes";
import last_build_timestamp from '../last_build_timestamp';



export class ReleaseVersionHandler {
    apiURLs = {
        version_histories: URLs.VERSION_HISTORY,
        last_build_version: URLs.LAST_BUILD_VERSION,
	};

	backendCurrentVersion = store.getters.appBackendVersion;
	backendBuildTime = store.getters.appBackendLastBuildTime;
	backendVersionHistory = null;

	frontendCurrentVersion = store.getters.appFrontendVersion;
	frontendVersionHistory = null;

	constructor(log, store) {
		this.log = log;
		this.store = store;
	}
	
	getFrontendBuildTime(){
		System.import("@"+process.env.LAST_BUILD_TIMESTAMP_FILEPATH); 
		return last_build_timestamp.buildTimeStamp;
	}

	getReleaseVersionData(){
		return {
			backendCurrentVersion: this.backendCurrentVersion,
			backendBuildTime: this.backendBuildTime,
			frontendCurrentVersion: this.frontendCurrentVersion,
			frontendBuildTime: this.getFrontendBuildTime(),
			backendVersionHistory: this.backendVersionHistory,
			frontendVersionHistory: this.getFrontendVersionHistories()
		}
	}

	fetchBackendLastBuildVersion() {
		return new Promise((resolve, reject) => {
			if (utils.isPresent(this.store.getters.appBackendVersion) && utils.isPresent(this.store.getters.backendBuildTime)){
				this.backendCurrentVersion = this.store.getters.appBackendVersion;
				this.backendBuildTime = this.store.getters.backendBuildTime;	
			} else {
				this.getBackendLastBuildVersion()
				.then(response => {
					this.backendCurrentVersion = response.data.data.version;
					this.store.commit('setBackendVersion', this.backendCurrentVersion);
					this.backendBuildTime = response.data.data.build_time;	
					this.store.commit('setBackendLastBuildTime', this.backendBuildTime);
					resolve(response.data);
				})
				.catch(error => {
					this.entityItems = [];
					this.log.error(error);
					reject(error);
				})
				.finally(() => {});
			}
		});
	}

	fetchBackendVersionHistories() {
		return new Promise((resolve, reject) => {
			this.getBackendVersionHistories()
			.then(response => {
				this.backendVersionHistory = response.data.data;
				resolve(response);
			})
			.catch(error => {
				this.entityItems = [];
				this.log.error(error);
				reject(error);
			})
			.finally(() => {});
		});
	}

	getBackendVersionHistories() {
		this.log.debug('Get backend version histories');
		return utils.apiCaller(constants.HTTP_METHODS.GET, this.apiURLs.version_histories, null, false)
	}

	getBackendLastBuildVersion() {
		this.log.debug('Get backend last build version');
		return utils.apiCaller(constants.HTTP_METHODS.GET, this.apiURLs.last_build_version, null, false)
	}	

	getFrontendLastBuildVersion() {
		this.log.debug('Get frontend last build version');
		this.frontendCurrentVersion = store.getters.appFrontendVersion;
		this.frontendBuildTime = this.getFrontendBuildTime();
		
		return {
			version: this.frontendCurrentVersion,
			build_time: this.frontendBuildTime
		}
	}
	
	getFrontendVersionHistories() {
		this.log.debug('Get frontend version histories');
		this.frontendVersionHistory = releaseNotes.FRONTEND_VERSION_HISTORY;
		return this.frontendVersionHistory;
	}
}
