import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './resources/i18n.js';
//import validations from '@/commons/validations.js';
import { VueEditor } from "vue2-editor";

import VueLogger from 'vuejs-logger';
import formatters from '@/components/Utils/formatters';
import validation from '@/components/Utils/validation.js';
import utils from '@/components/Utils/utils';
import constants from '@/commons/constants';
import VueToastify from "vue-toastify";

Vue.prototype.$formatters = formatters;
Vue.prototype.$validations = validation;
Vue.prototype.$constants = constants;
Vue.prototype.$utils = utils;
Vue.config.productionTip = false
//Vue.prototype.$validations = validations;

const isProduction = process.env.NODE_ENV === 'production';

const options = {
    isEnabled: true,
    logLevel : isProduction ? 'error' : 'debug', //logLevels :  ['debug', 'info', 'warn', 'error', 'fatal']
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
};

Vue.use(VueLogger, options);

Vue.use(VueToastify, {
    errorDuration: 3000,
    successDuration: 2000,
    canTimeout: 2000,
    canPause: true,
    position: "bottom-right",
    singular: false,
    hideProgressbar: false,
    defaultTitle: false,
    theme: "dark"
});

var gvApp = new Vue({
  router,
  store,
  vuetify,
  VueEditor,
  i18n,
  render: h => h(App)
}).$mount('#app')

export default gvApp;