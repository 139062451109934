import DataComponentBase from '@/commons/DataComponentBase';
import Authentication from '@/commons/Authentication';
import { ReleaseVersionHandler } from "@/commons/ReleaseVersionHandler";
import { AgentEntity } from '@/components/Agents/AgentEntity';
import { isPresent } from '@/components/Utils/utils';


export default {
    name: 'App',
    extends: DataComponentBase,

    data() {
        return {
            menu: {
                drawer: true,
                mini: false,
                items: [
                    { title: this.$i18n.t('menu.partners'), icon: 'mdi-handshake-outline', route: '/partners' },
                    { title: this.$i18n.t('menu.agents'), icon: 'mdi-account-outline', route: '/agents?partner_id=0' },
                    { title: this.$i18n.t('menu.agentGroups'), icon: 'mdi-account-group-outline', route: '/agentGroups' },
                    { title: this.$i18n.t('menu.banks'), icon: 'mdi-bank-outline', route: '/banks' },
                    { title: this.$i18n.t('menu.products'), icon: 'mdi-shopping-outline', route: '/products' },
                    { title: this.$i18n.t('menu.messages'), icon: 'mdi-message-text-outline', route: '/messages' },
                ],
            },
            customMenu: {
                list: [],
                edit: false,
                dialog: {
                    details: false,
                    edit: false,
                    delete: false
                },
                selectedItem: {},
            },

            userProfile: {
                data: {},
                passwordChange: {},
                dialog: false,
                window: 0,
            },
            snackbarEnabled: this.$store.getters.snackbarEnabled,
            releaseVersionHandler: new ReleaseVersionHandler(this.$log, this.$store),
            entityHandler: new AgentEntity(this.$log, this.$store),
        }
    },

    created() {
        this.initCustomMenu();
        this.initLoggedInUser();
    },

    computed: {
        isLoading() {
            return this.$store.getters.asyncProgess.length > 0 || this.$store.getters.myLoading;
        },

        storeSnackbarEnabled() {
            return this.$store.getters.snackbarEnabled;
        },

        statusAdditionalMessage() {
            return this.$store.getters.statusAdditionalMessage ? this.$store.getters.statusAdditionalMessage : '';
        },

        loggedInUserObj() {
            return this.$store.getters.getLoggedInUserObj;
        }
    },

    methods: {
        createEntityObj(entity = null) {
            let returnObj = {};
            returnObj.email = entity === null || entity.email === null ? '' : entity.email;
            returnObj.name = entity === null || entity.name === null ? '' : entity.name;
            returnObj.firm_name = entity === null || entity.firm_name === null ? '' : entity.firm_name;
            return returnObj;
        },

        initCustomMenu() {
            this.customMenu.list = require('@/data/customMenu.json').data;
        },

        initLoggedInUser() {
            this.userProfile.data = this.createEntityObj(this.$store.getters.getLoggedInUserObj);
        },
        toggleNavigationDrawer() {
            this.menu.drawer = !this.menu.drawer
        },
        toggleNavigationDrawerMini() {
            this.menu.mini = !this.menu.mini
        },
        isActiveMenu(route) {
            return this.$route.path == route;
        },
        openLink(url) {
            window.open(url, '_blank')
        },

        openUserProfileDialog() {
            this.userProfile.dialog = true;
        },
        switchToUserProfile() {
            this.userProfile.window = 0;
        },
        switchToPasswordChanege() {
            this.userProfile.window = 1;
        },
        saveUserProfile() {
            this.userProfile.dialog = false;
        },

        createEntityObjChangePassword(entity = null) {
            var md5 = require("md5");
            let returnObj = {};
            returnObj.old_password = isPresent(entity) && isPresent(entity.old) ? md5(entity.old) : '';
            returnObj.password = isPresent(entity) && isPresent(entity.new) ? md5(entity.new) : '';
            returnObj.password_confirmation = isPresent(entity) && isPresent(entity.newAgain) ? md5(entity.newAgain) : '';
            return returnObj;
        },

        sendPasswordChange() {
            if (this.$refs.passwordChangeForm.validate()) {
                let entity = this.createEntityObjChangePassword(this.userProfile.passwordChange);
                this.entityHandler.changePassword(entity)
                    .then((response) => {
                        this.$store.commit('setStatusCode', response.status);
                        this.$store.commit('setSnackbarEnabled', true);
                        this.userProfile.passwordChange = this.createEntityObjChangePassword();
                        this.switchToUserProfile();
                    })
                    .catch(error => {
                        this.$log.error(error);
                    })
                    .finally(() => { });
            } else {
                this.$store.commit('setStatusCode', 'invalidFormData');
                this.$store.commit('setSnackbarEnabled', true);
            }
        },
        
        validateReenteredPassword(password, confirmPassword) { //FIXME: közösíteni kell az agent-el
            if (password == confirmPassword) {
                return true;
            } else {
                return this.$i18n.t('validation.reenteredPassword');
            }
        },

        openDialogCustomMenuItemNew() {
            this.customMenu.selectedItem = {};
            this.customMenu.dialog.edit = false;
            this.customMenu.dialog.details = true;
        },
        openDialogCustomMenuItemDetails(item) {
            this.customMenu.selectedItem = JSON.parse(JSON.stringify(item));
            this.customMenu.dialog.edit = true;
            this.customMenu.dialog.details = true;
        },
        openDialogCustomMenuItemDelete(item) {
            this.customMenu.selectedItem = JSON.parse(JSON.stringify(item));
            this.customMenu.dialog.delete = true;
        },
        closeDialog() {
            this.customMenu.dialog.details = false;
            this.customMenu.dialog.edit = false;
            this.customMenu.dialog.delete = false;
            this.initCustomMenu();
        },

        saveCustomMenuItem() {
            //..
            this.closeDialog();
        },
        deleteCustomMenuItem() {
            //..
            this.closeDialog();
        },

        logout() {
            Authentication.logout();
            this.loadPage('/login');
        },

        releaseVersionData() {
            return this.releaseVersionHandler.getReleaseVersionData();
        },

        currentPath() {
            return this.$route.path;
        },

        environment() {
            return this.$store.getters.environmentData;
        }
    },
    watch: {
        loggedInUserObj() {
            this.initLoggedInUser();
        },
        storeSnackbarEnabled() {
            this.snackbarEnabled = this.storeSnackbarEnabled;
        },
        snackbarEnabled() {
            this.$store.commit('setSnackbarEnabled', this.snackbarEnabled);
            if (this.snackbarEnabled) {
                // Prepare snackbar text
                let localizationTextKey = "status." + this.statusCode;
                let mainText = this.$i18n.t(localizationTextKey);
                let text = (this.statusAdditionalMessage) ? mainText + '<br /><br /><span class=\'snackbarAdditionalMessage\'>' + this.statusAdditionalMessage + '</span>' : mainText;

                if (this.snackbarEnabled) {
                    if (this.statusCode[0] == "2") {
                        this.$vToastify.success(text, this.$i18n.t("common.snackbar.SUCCESS"));
                    } else {
                        this.$vToastify.error(text, this.$i18n.t("common.snackbar.ERROR"));
                    }
                }
            }
            this.$store.commit('setSnackbarEnabled', false);
        },
    }

};
